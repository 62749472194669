<template>
  <div>
    <div v-if="video_ready" :class="loading_video ? 'hidden' : 'block'" class="hls-plyr-player">
      <vue-plyr
        ref="plyr"
        :class="playerClass"
        :options="options"
        class="player-wrapper">
        <video
          ref="video"
          :class="playerClass"
          :data-poster="poster_url"
          preload="none"
        ></video>
      </vue-plyr>
    </div>
    <div v-if="loading_video" class="simple-spinner mt-4 ml-4">
      <span></span>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js'

export default {
  name: 'HlsPlayer',
  props: {
    osidexId: {
      type: String,
      required: true
    },
    videoId: {
      type: String,
      required: false
    },
    poster: {
      type: String,
      default: '',
      required: false
    },
    playerClass: {
      type: String,
      required: false
    },

  },
  data() {
    return {
      hls: null,
      source: null,
      video_ready: false,
      loading_video: true,
      quiz_skip: false,
      options: {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
        settings: ['captions', 'quality', 'speed', 'loop'],
        quality: {},
        previewThumbnails: {},
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true
        },
        playsinline: true
      },
      key: 0
    }
  },
  computed: {
    active_user_info () {
      return this.$store.state.AppActiveUser;
    },
    apple_playback() {
      return document.createElement('video').canPlayType('application/vnd.apple.mpegURL')
    },
    local_storage_key () {
      return `${this.active_user_info.id}-${this.$route.params.courseId}-${this.$route.params.moduleId}-${this.$route.params.lessonId}-${this.videoId}-${this.osidexId}`
    },
    player() {
      return this.$refs.plyr.player
    },
    poster_url() {
      return `https://app-cdn.rapidseminars.com/thumbnails/${this.poster}.jpeg`
    },
    video() {
      return this.$refs.video
    },

  },
  methods: {
    /* decryptVideo() {
      this.$http.get(`https://get-video.rapidseminars.com/?q=init&osidexId=${this.osidexId}&videoId=${this.videoId}&sAuth=55f73eb4-0cf0-454a-bdd7-604c950da8de&tAuth=${this.tokenAuth}`, {headers: {}})
        .then(response => {

          if (response.data) {
            const decrypted = this.$CryptoJS.AES.decrypt(response.data, "6ca855cd-31ac-4fd7-be72-1dd284829da4").toString(this.CryptoJS.enc.Utf8)

            let parsed = decrypted
              .replaceAll("*&@%AJWIRNS", "H")
              .replaceAll("#AvL*lAGfN", "u")
              .replaceAll("EINQd*&qdAR@N", "t")
              .replaceAll("QO#RHR@9SNeqwI", "s")
              .replaceAll("Q2#3@***Kxn", "r")
              .replaceAll("F!%TI%:jvIw", "o")
              .replaceAll("KKD#lAD*%", "m")
              .replaceAll("F3%6srqUE%v0S", "l")
              .replaceAll("zI@eYw%s!C", "k")
              .replaceAll("wO*%eMoE#R", "j")
              .replaceAll("3P*@SW@{DN", "h")
              .replaceAll("0P%EddbkE7T5", "d")
              .replaceAll("GcYg%z4RWO", "c")
              .replaceAll("YX!KRLEY%A", "b")
              .replaceAll("%y>jj6rPUykC", "a")

            const videoUrl1 = parsed.match(new RegExp('(?<=@#\\*RK#\\*>MR#)(.|\\n|[\\s\\S]*?)(?=@#\\*RK#\\*>MR#)', 'g'))
            const videoUrl2 = parsed.match(new RegExp('(?<=>Q@\\*AJ@\\^#H<)(.|\\n|[\\s\\S]*?)(?=>Q@\\*AJ@\\^#H<)', 'g'))

            this.initProcess(videoUrl1[0] + videoUrl2[0])
          }

        })
        .catch(() => {
          return this.$vs.notify({
            title: 'Failed to load video',
            text: 'Please contact Rapid Education staff for more information',
            color: 'danger',
            position: 'top-right',
          });
        });
    }, */
    decryptVideo () {

    },
    durationSetSavedTime () {
      const time = window.localStorage.getItem(this.local_storage_key)
      if (time) {
        this.video.currentTime = time
      }
    },
    loadAlternateSource(apple) {
      return new Promise(resolve => {
        this.video_ready = true
        resolve()
      }).then(() => {
        this.durationSetSavedTime()
        this.video.src = this.source;
        if (apple) this.video.type = 'application/vnd.apple.mpegURL'
        document.getElementsByClassName('plyr__poster')[0].className += ` ${this.playerClass}`
        setTimeout(() => {
          this.setupPlayer()
          this.loading_video = false
          this.$emit('video_loaded')
        }, 100)
      })
    },
    initHlsVideo(hls) {
      return new Promise(resolve => {
        this.video_ready = true
        resolve()
      }).then(() => {
        this.durationSetSavedTime()
        hls.attachMedia(this.video)
        this.$once('hook:beforeDestroy', () => {
          hls.stopLoad()
          hls.destroy()
        })
        document.getElementsByClassName('plyr__poster')[0].className += ` ${this.playerClass}`
        setTimeout(() => {
          this.setupPlayer()
          this.loading_video = false
          this.$emit('video_loaded')
        }, 1300)
      })
    },
    initProcess() {
      const hls = new Hls()
      hls.autoLevelCapping = 2;

      if (Hls.isSupported()) {
        this.hls = hls
        hls.loadSource(this.source)
        const self = this
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {

          if (hls.levels.length >= 4) {
            hls.removeLevel(3);
          }

          const availableQualities = hls.levels.map((l) => l.height)
          const index = availableQualities[availableQualities.indexOf(1080)]
          self.options.quality = {
            default: index,
            options: availableQualities,
            forced: true,
            onChange: (e) => self.updateQuality(e)
          }
         self.initHlsVideo(hls)
        })
        return
      }

      if (this.apple_playback) {
        return this.loadAlternateSource(true)
      }

      if (!Hls.isSupported()) {
        return this.loadAlternateSource()
      }

    },
    setupPlayer () {
      const self = this
      this.player.on('play', function() {
      });
      this.player.on('pause', function() {
      });
      this.player.on('qualitychange', function() {
      });
      this.player.on('stalled', function() {
      });
      this.player.on('error', function() {
      });
      this.player.on('ended', function() {
        self.$emit('video_ended')
      });
      this.player.on('timeupdate', function() {
        if(self.video.currentTime !== 0) {
          self.updateCurrentTime(self.video.currentTime)
        }
       // if (self.video.duration && self.video.duration && self.video.currentTime) {
        //  self.$emit('quiz_skip')
       // }
      });
    },
    updateCurrentTime (time) {
      if(time > this.video.duration * 0.80 && !this.quiz_skip) {
        console.log('READY')
        this.quiz_skip = true
        this.$emit('quiz_skip')
      }
      if(this.video.duration && this.video.duration - time < 30) {
        return window.localStorage.setItem(this.local_storage_key, 0)
      }
      if(this.video.duration && time < 15) {
        return window.localStorage.setItem(this.local_storage_key, 0)
      }
      window.localStorage.setItem(this.local_storage_key, time)
    },
    updateQuality(newQuality) {
      const newQualityParsed = parseInt(newQuality)
      this.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQualityParsed) {
          this.hls.currentLevel = levelIndex
        }
      })
    },

  },
  beforeMount() {
    //this.decryptVideo()
    this.source = `https://serve.osidex.delivery/vod/${this.osidexId}`
    this.initProcess()
  }
}
</script>
<style>
@import "vue-plyr.css";

.plyr__poster {
  background-color: transparent;
  background-size: cover;
}

.plyr__video-wrapper {
  z-index: -1;
}

/*
.plyr__video-wrapper::before {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 10;
  content: '';
  height: 37.5%;
  width: 37.5%;
  max-height: 40rem;
  max-width: 40rem;
  background: url('https://cdn.discordapp.com/attachments/711343441359143061/756730028057952298/rapideduwhite.png') no-repeat;
  background-size: 37.5% auto, auto;
}
 */
</style>
