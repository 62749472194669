<template>
  <div id="LearnRenderer" class="lessonQuizHeight w-full" >

    <!--- TOP PROGRESS BAR START --->
    <div class="absolute w-full z-10" v-if="!finishedQuiz">
      <div class="p-8 sm:p-10 md:p-12 lg:p-16 pt-8">
        <div class="flex items-center justify-center">
          <vs-progress :percent="currentProgress" class="w-full LearnProgressBar" color="success"
                       height="14"></vs-progress>
        </div>
      </div>
    </div>
    <!--- TOP PROGRESS BAR END --->

    <!--- LEARN APP START --->
    <div class="lessonQuizHeight w-full relative flex items-center justify-center">

      <div v-if="!finishedQuiz" class="LearnAppContainer">
        <div>
          <h2 v-if="currentChallenge.question" class="font-light mb-5">{{ currentChallenge.question }}</h2>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-3" :key="selectedKey">
            <vx-card v-for="(choice, index) in currentChallenge.answers" :class="index === currentChallenge.selected ? 'cardSelected' : ''"
                     class="multiChoiceCard cursor-pointer" @click="multiChoiceSelectOption(index)" :key="index">
              <div class="flex items-center justify-center h-full w-full">
                <h5 class="font-bold text-center">{{ (index + 10).toString(36).toUpperCase() + ') ' + choice }}</h5>
              </div>
            </vx-card>
          </div>
        </div>
      </div>

      <div v-if="finishedQuiz && !reviewQuiz" class="m-8">
        <div class="rounded-lg p-5 bg-danger mb-6" v-if="quizResults.correct !== quizResults.total">
          <h4 class="font-bold flex items-center">
            <feather-icon icon="InfoIcon" svgClasses="h-6 w-6 mr-2"></feather-icon>
            In Order To Complete The Lesson You Must Achieve 100% On The Quiz
          </h4>
        </div>
        <div class="flex items-center justify-center">
          <lottie-animation :autoPlay="true"
                            :loop="false"
                            :speed="1" :width="275"
                            path="https://assets4.lottiefiles.com/packages/lf20_01bh9ld2.json"/>
        </div>
        <h1 class="mt-1 font-bold text-center" style="font-size: 3.2rem">Lesson Completed!</h1>
        <h2 class="mt-4 font-light text-center">You got {{ quizResults.correct }} out of {{ quizResults.total }}!</h2>
        <div class="flex items-center justify-center mt-8">
          <vs-button  class="mr-2" color="primary" icon-pack="feather" icon="icon-book" size="large" @click="reviewQuiz = !reviewQuiz" >Review Answers</vs-button>
           <vs-button class="ml-2" color="warning" icon-pack="feather" icon="icon-refresh-cw" size="large" @click="$emit('quiz_restart')">Restart Quiz</vs-button>
        </div>
        <div class="flex items-center justify-center mt-6">
          <vs-button color="success" icon-pack="feather" icon="icon-play" size="large"  @click="nextLesson">Next Lesson</vs-button>
        </div>
      </div>


      <div v-if="finishedQuiz && reviewQuiz" >
          <div class="pl-20 pr-20 my-12 flex items-center justify-between">
            <vs-button  color="primary" icon-pack="feather" icon="icon-arrow-left" size="large" @click="reviewQuiz = !reviewQuiz" >Back</vs-button>
            <vs-button class="hidden lg:flex"  color="success" icon-pack="feather" icon="icon-play" size="large"  @click="nextLesson">Next Lesson</vs-button>
          </div>
          <div class="pl-20 pr-20" style="max-height: 70vh; overflow: auto">
            <div v-for="(challenge, index) in challengesCompleted" class="mb-10">
              <!--- {{quizResults.answers[index]}} --->
              <!--- {{challenge.selected}} --->
              <div class="flex items-center justify-between mb-5">
                <h2 v-if="challenge.question" class="font-light pr-20">{{ challenge.question }}</h2>
                <feather-icon v-if="quizResults.key[index] === challenge.selected" icon="CheckCircleIcon" svgClasses="h-8 w-8 text-success"/>
                <feather-icon v-else icon="XCircleIcon" svgClasses="h-8 w-8 text-danger"/>

              </div>
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-3">
                <vx-card v-for="(choice, index) in challenge.answers" :class="index === challenge.selected ? 'cardSelected' : ''"
                         class="multiChoiceCard cursor-pointer" @click="multiChoiceSelectOption(index)" :key="index">
                  <div class="flex items-center justify-center h-full w-full">
                    <h5 class="font-bold text-center">{{ choice }}</h5>
                  </div>
                </vx-card>
              </div>
            </div>
          </div>
      </div>
    </div>
    <!--- LEARN APP END --->

    <!--- BOTTOM ACTION BAR START --->
    <div v-if="!finishedQuiz" :class="`${challengeScore.active ? 'answerDisplay' : ''}`"
         class="bottomActionBar absolute w-full z-10 bottom-0">

      <div v-if="!challengeScore.active" class="p-12 pt-8 pb-8 w-full">
        <div class="flex items-center justify-center w-full">
          <div class="actionButtons flex items-center justify-between">
           <!--- <vs-button :disabled="!skipButton" class="skipButton" color="transparent"
                       type="flat" @click="completedChallenge(true)">SKIP
            </vs-button> --->
            <div></div>
            <vs-button :disabled="!nextButton" class="nextButton" @click="completedChallenge(false)">NEXT</vs-button>
          </div>
        </div>
      </div>

      <div v-if="challengeScore.active" class="p-12 pb-2 pt-2 w-full">
        <div class="flex items-center justify-center w-full">
          <div class="actionButtons flex items-center justify-between">
            <div class="flex items-center">
              <lottie-animation :autoPlay="true"
                                :loop="false"
                                :path="challengeScore.correct
                                ? 'https://assets1.lottiefiles.com/packages/lf20_pmYw5P.json'
                                : 'https://assets4.lottiefiles.com/private_files/lf30_chkimb7d.json'" :speed="1"
                                :width="challengeScore.correct ? 110 : 70"/>
              <div>
                <h2 v-if="challengeScore.correct" class="text-success ml-4">Saved Response</h2>
                <h2 v-if="!challengeScore.correct" class="text-danger ml-4">Skipped Question</h2>
                <h5 v-if="challengeScore.text" class="text-light mt-2 ml-4">{{ challengeScore.text }}</h5>
              </div>
            </div>

            <vs-button class="nextButton" @click="nextChallenge">CONTINUE</vs-button>
          </div>
        </div>
      </div>


    </div>
    <!--- BOTTOM ACTION BAR END --->

  </div>
</template>

<script>
import LottieAnimation from '@/components/lottie/LottieAnimation'

export default {
  data() {
    return {
      currentChallengeIndex: 0,
      currentChallenge: null,
      nextButton: false,
      challengeScore: {
        active: false,
        correct: false,
        text: ''
      },

      originalQuestions: [],
      challengesToComplete: [],
      challengesCompleted: [],

      finishedQuiz: false,
      reviewQuiz: false,
      skipButton: true,

      quizResults: {
        total: 0,
        correct: 0,
      },

      selectedKey: 0


    }
  },
  props: {
    name: {
      type: String,
      required: false
    },
    lessonId: {
      type: String,
      required: true
    },
    questions: {
      type: Array,
      required: true
    },
  },
  components: {
    LottieAnimation
  },
  computed: {
    currentProgress() {
      return (this.challengesCompleted.length / (this.challengesToComplete.length + this.challengesCompleted.length)) * 100
    }
  },
  methods: {
    review() {
      this.crtx();
    },
    completedChallenge(skip) {
      if (skip === true) {
        this.updateChallengeScore(true, false, 'You will be able to answer this question later on in the quiz')
        this.promptIncorrectSound.play()
        this.challengesToComplete.splice(this.currentChallengeIndex, 1)
        return this.challengesToComplete.push(this.currentChallenge)
      }
      if (skip === false) {
        this.updateChallengeScore(true, true, 'You will get the score at the end of the quiz')
        this.promptCorrectSound.play()
        this.challengesToComplete.splice(this.currentChallengeIndex, 1)
        this.challengesCompleted.push(this.currentChallenge)
      }
    },
    nextChallenge() {
      if (this.challengesToComplete.length === 0) {
        this.lessonCompleteSound.play()
        this.quizMark()
        return this.finishedQuiz = true
      }

      this.skipButton = false
      this.currentChallenge = this.challengesToComplete[this.currentChallengeIndex]
      this.currentChallenge.selected = null
      this.nextButton = false
      this.updateChallengeScore(false, false, null)

      setTimeout(() => {
        this.skipButton = true
      }, 1000)
    },
    multiChoiceSelectOption(choice) {
      this.currentChallenge.selected = choice
      this.selectedKey++
      this.nextButton = true
    },
    quizMark() {

      let answers = {}

      this.challengesCompleted.forEach((item, index) => {
        answers[index] = item.selected
      })

      this.$http.post(`lessons/${this.lessonId}/quiz/submissions`, {"answers": answers})
        .then(response => {
          if (response.data) {
            this.quizResults.answers = response.data.data.answers
            this.quizResults.key = response.data.data.key
            this.quizResults.total = response.data.data.total_questions
            this.quizResults.correct = response.data.data.total_correct
          }

          if (response.data.data.total_questions === response.data.data.total_correct) {
            this.$emit('quiz_mark_as_complete')
            this.complete = true
          } else {
            this.$vs.notify({
              color: 'danger',
              title: 'Not Completed Lesson',
              text: 'You must achieve 100% on the quiz to mark the lesson as complete.',
              position: 'top-right',
            });
          }
        });



    },
    updateChallengeScore(active, correct, text) {
      this.challengeScore.active = active
      this.challengeScore.correct = correct
      this.challengeScore.text = text
    },
    nextLesson() {
      if(this.quizResults.total !== this.quizResults.correct) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Lesson Will Not Be Completed',
          text: 'You must achieve a score of 100% on the quiz to complete the lesson, are you sure you want to proceed without redoing the quiz.',
          accept: this.nextLessonProceed,
          acceptText: 'Proceed',
        });
      }else{
        this.nextLessonProceed()
      }
    },
    nextLessonProceed () {
      this.$emit('quiz_end')
    },
    crtx() {
      this.promptCorrectSound = new Audio('https://d35aaqx5ub95lt.cloudfront.net/sounds/37d8f0b39dcfe63872192c89653a93f6.mp3')
      this.promptIncorrectSound = new Audio('https://d35aaqx5ub95lt.cloudfront.net/sounds/f0b6ab4396d5891241ef4ca73b4de13a.mp3')
      this.lessonCompleteSound = new Audio('https://d35aaqx5ub95lt.cloudfront.net/sounds/2aae0ea735c8e9ed884107d6f0a09e35.mp3')

      const questions = this.questions
      this.challengesToComplete = questions
      this.currentChallengeIndex = 0;
      this.currentChallenge = this.challengesToComplete[this.currentChallengeIndex]
      this.selectedKey = 0;
    }
  },
  mounted() {
    this.crtx();
  },
}
</script>
<style lang="scss">
#LearnRenderer {
  .lessonQuizHeight {
    min-height: 84vh !important;
  }

  @media (max-width: 738px) {
    .lessonQuizHeight {
      min-height: 100vh !important;
    }
  }

  //  background: #050035;
  .LearnProgressBar {
    background-color: rgba(0, 0, 0, 0.30);
    z-index: 999999;
    // max-width: 950px;
  }

  .LearnAppContainer {
    // padding-top: 10%;
    padding-bottom: 8.5%;
    max-width: 850px;
    width: 70%;
    font-size: 32px;
    font-weight: 600;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .multiChoiceCard {
    border: solid #e5e5e5;
    border-width: 1px 1px 1px 1px;
    transition: none;

    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }

    &:active {
      transform: translateY(2px) translateZ(0)
    }

    &.cardSelected {
      background: rgba(24, 153, 214, 0.80);
      border-color: #84d8ff;
    }

    .vx-card__body {
      height: 100%;
    }

    .vx-card__collapsible-content.vs-con-loading__container {
      height: 100%;
    }
  }

  .bottomActionBar {
    min-height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid rgba(229, 229, 229, 0.25);
    border-width: 2px 0 0 0;

    &.answerDisplay {
      border-color: transparent;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 0 0 0.5rem 0.5rem
    }
  }

  .actionButtons {
    max-width: 1100px;
    width: 100%;
  }

  .nextButton {
    font-weight: 600;
    font-size: 1.5rem;
    padding: 10px 30px 10px 30px;
    border-radius: 20px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:disabled {
      background: #afafaf !important;
    }
  }

  .skipButton {
    font-weight: 600;
    font-size: 1.5rem;
    padding: 10px 30px 10px 30px;
    border: 2px solid rgba(229, 229, 229, 0.25) !important;
    color: #FFF !important;
    border-radius: 20px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:disabled {
      opacity: 0%;
    }
  }
}


</style>
