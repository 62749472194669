<template>

  <div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card class="mb-5">

          <div class="hidden lg:flex items-center justify-between">
            <div class="flex items-center">
              <div>
                <h3>{{ lesson.name }}</h3>
                {{ lesson.description }}
              </div>
            </div>
            <div class="flex items-center">
              <div class="text-right">
                <p class="font-bold"> {{ course.name }}</p>
                <span class="font-light text-primary cursor-pointer"
                      @click="$router.push(`/courses/${course.id}`)">Back To Course Page</span>
              </div>
              <img :src="`https://app-cdn.rapidseminars.com/thumbnails/${course.thumbnail_id}.jpeg`" alt="thumbnail"
                   class="rounded cursor-pointer ml-4"
                   width="100"
                   @click="$router.push(`/courses/${course.id}`)">
            </div>
          </div>

          <div class="block lg:hidden">
            <div class="mb-4 flex items-center">
              <div>
                <h3>{{ lesson.name }}</h3>
                {{ lesson.description }}
              </div>
            </div>
            <span class="font-light text-primary cursor-pointer"
                  @click="$router.push(`/courses/${course.id}`)">Back To Course Page</span>
          </div>


        </vx-card>
      </div>
    </div>

    <div class="vx-row">

      <div class="vx-col w-full lg:w-8/12">

        <div v-if="!quiz_active && !feedback_active" class="w-full">
          <!--- Player(s) --->
          <div :key="key" class="w-full mb-4">
            <div v-if="video.platform === 'osidex' && osidex_id">
              <hls-player :osidex-id="osidex_id" :poster="video.thumbnail_id"
                          :video-id="video.id" player-class="rounded-lg" v-on:quiz_skip="quiz_skip = true"
                          v-on:video_ended="videoEnded" v-on:video_loaded="video_loaded = true"/>
            </div>
          </div>
        </div>

        <div v-if="feedback_active">
          <h1>Feedback Survey</h1>

          <div class="flex items-center justify-between mt-1 mb-3">
            <p class="pr-10">Please complete the feedback survey below to help improve this course, when complete press the <span class="font-bold">Completed Survey</span> button</p>
            <vs-button @click="moduleComplete"  icon="icon-check-circle" icon-pack="feather">Completed Survey</vs-button>
          </div>

          <iframe class="w-full " height="800" :src="module.end_of_module_iframe_url"></iframe>
        </div>

        <div v-if="quiz_active && !feedback_active"  class="w-full mb-4 vx-card rounded-lg">
          <lesson-quiz :questions="quiz.questions" :key="quiz_key"
                       :lessonId="lesson.id" v-on:quiz_end="quizEnd" v-on:quiz_restart="quizRestart" v-on:quiz_mark_as_complete="quizComplete"/>
          <!--- <div v-for="(item, index) of quiz.questions" :key="index">

            <h2>{{ item.question }}</h2>

            <div class="grid grid-cols-2 gap-4 my-4">
              <vs-button v-for="(q, q_index) of item.answers"
                         :key="q_index" :class="quiz.questions[index].selected === q_index ? 'bg-success text-white' : ''"
                         :color="quiz.questions[index].selected === q_index ? 'success' : 'primary'"
                         class="font-bold" type="border"
                         @click="questionChooseAnswer(index, q_index)">{{ q }}
              </vs-button>
            </div>

            <p v-if="quiz.questions[index].selected === 'missing'" class="font-semibold text-danger">Please select an
              option from the selection above</p>

            <vs-divider/>

          </div>

          <div class="flex justify-between">
            <div class="flex align-middle items-center">
              <vs-button v-if="quiz.response && quiz.response.total_correct === quiz.response.total_questions" class="mr-3 font-semibold" color="success"
                         @click="quizEnd">Continue
                To Next Lesson
              </vs-button>
              <vs-button v-else class="mr-3 font-semibold" @click="quizMark">Mark Quiz</vs-button>
              <h2 v-if="quiz.response" :class="quiz.response.total_correct === quiz.response.total_questions ? 'text-success' : 'text-warning'"
                  class="ml-3 font-semibold">
                {{ quiz.response.total_correct }} / {{ quiz.response.total_questions }}</h2>
            </div>
            <vs-button v-if="quiz.response && quiz.response.total_correct !== quiz.response.total_questions" class="mr-3 font-semibold" color="danger"
                       @click="quizEnd">Skip Quiz
            </vs-button>
          </div>
          <p class="mt-6">Please Submit The Quiz To Proceed To The Next Lesson</p>
         --->
        </div>



        <div v-if="video_loaded && !feedback_active" id="AppCollapse">
          <div v-if="quiz && !quiz_active" class="flex justify-between items-center mb-4">
            <div  class="flex items-center">
              <feather-icon icon="InfoIcon" svgClasses="h-10 w-10 mr-3"/>
              <div>
                <h4 class="font-light">This Lesson Contains a Check On Learning Quiz</h4>
                <p class="font-light">The quiz will automatically begin once the video is complete.</p>
              </div>
            </div>
            <vx-tooltip text="You must watch minimum 80% of the video to begin the quiz.">
              <vs-button :disabled="!quiz_skip" color="primary" icon-pack="feather" icon="icon-skip-forward"
                         @click="quizStart" class="no-select">Skip To Quiz</vs-button>
            </vx-tooltip>
          </div>
          <div v-if="lesson.instructions" class="mb-4">
            <h2 class="font-bold mb-2 ml-1">Instructions</h2>
            <vs-collapse class="p-0 m-0">
              <div class="mb-3 w-full">
                <vs-collapse-item class="w-full p-0 m-0">
                  <vx-card>
                    <p>
                      {{ lesson.instructions }}
                    </p>
                  </vx-card>
                </vs-collapse-item>
              </div>
            </vs-collapse>
          </div>
          <div v-if="lesson.attachment_ids.length > 0" class="mb-4">
            <vs-collapse class="p-0 m-0">
              <vx-card>
                <vs-collapse-item class="w-full p-0 m-0">
                  <div slot="header">
                    <h2 class="font-bold mb-2 ml-1">Attachments</h2>
                  </div>

                  <div v-for="(attach, index) in lesson.attachments" :key="attach.id" class="mb-3">
                    <div class="flex items-center justify-between">
                      <div class="flex items-center justify-center">
                        <feather-icon :icon="`${attach.type === 'hyperlink' ? 'Link' : 'File'}Icon`"
                                      class="text-danger"/>
                        <p>&nbsp;{{ attach.name }}</p>
                      </div>
                      <vs-button v-if="attach.type === 'hyperlink'" :href="attach.target" color="danger" size="small"
                                 target="_blank">
                        Open
                      </vs-button>
                      <vs-button v-if="attach.type === 'file'" color="danger" size="small"
                                 @click="initiateDownload(attach.id)">
                        Download
                      </vs-button>
                    </div>
                  </div>

                </vs-collapse-item>
              </vx-card>
            </vs-collapse>
          </div>
        </div>

      </div>

      <div class="vx-col w-full lg:w-4/12">

        <div class="mb-6">
          <h2 class="font-bold mb-3 ml-1">Navigate Lessons</h2>
          <div class="flex items-center">
            <vs-button class="mr-2 w-full text-black" color="light" icon="icon-arrow-left-circle" icon-pack="feather"
                       @click="previousLesson">
              Previous
            </vs-button>

            <vs-button class="ml-2 w-full text-black" color="light" icon="icon-arrow-right-circle" icon-after
                       icon-pack="feather" @click="nextLesson">
              Next
            </vs-button>
          </div>
        </div>
        <div id="ModuleSelector" class="mb-6">
          <h2 class="font-bold mb-2 ml-1">Modules</h2>
          <vs-collapse accordion class="p-0 m-0" type="accordion">
            <div v-for="__module in course.modules" class="mb-3 w-full">
              <vs-collapse-item class="w-full p-0 m-0">
                <vx-card slot="header" :class="__module.id === module.id ? 'bg-primary' : ''" class="p-0 m-0 ">
                  <div class="flex items-center ">
                    <feather-icon class="mr-3" icon="PackageIcon" svgClasses="h-6 w-6"/>
                    <h3 class="font-light">{{ __module.name }}</h3>
                  </div>
                </vx-card>
                <vx-card class="mt-2">
                  <div v-for="(__lesson, index) in __module.lessons" :key="__lesson.id"
                       @click="goToLesson(__lesson.id, __module.id)">
                    <div :style="__lesson.id === lesson.id ? 'background: rgba(0, 0, 0, 0.34)' : ''"
                         class="p-3 rounded">
                      <div class="vx-row">
                        <div class="vx-col w-4/5">
                          <h5 class="" :class="__lesson.id === lesson.id ? 'text-primary font-bold ' : ''">
                            {{ __lesson.name }} <!---<span v-if="__lesson.id === lesson.id">&mdash; <span
                            class="text-success">Watching Now</span></span>--->
                          </h5>
                          <div>
                            <span class="font-light text-success" v-if="progress.modules[__module.id][__lesson.id]">Completed</span>
                            <span class="font-light text-dark" v-else>Not Completed</span>
                            <span class="font-light text-dark"> - {{ __lesson.duration | duration }}</span>
                          </div>
                        </div>
                        <div class="vx-col w-1/5 flex items-center justify-center">
                          <feather-icon
                            :class="`text-${progress.modules[__module.id][__lesson.id] ? 'success' : 'dark'}`"
                            icon="CheckCircleIcon"/>
                        </div>
                      </div>
                    </div>
                  </div>

                </vx-card>
              </vs-collapse-item>
            </div>
          </vs-collapse>
        </div>

      </div>
    </div>
  </div>


</template>

<style lang="scss">

.thumbnail {
  width: 15rem;
}

</style>

<script>
import VueApexCharts from 'vue-apexcharts';
import 'swiper/dist/css/swiper.min.css';
import {swiper, swiperSlide} from 'vue-awesome-swiper';
import HlsPlayer from "../../components/plyr/HlsPlayer";
import LessonQuiz from "./components/LessonQuiz";

export default {
  components: {
    VueApexCharts,
    swiper,
    swiperSlide,
    HlsPlayer,
    LessonQuiz
  },
  data() {
    return {
      course: {},
      module: {},
      lesson: {},
      video: {},
      quiz: {},
      quiz_key: 0,
      quiz_skip: false,
      quiz_active: true,
      osidex_id: undefined,
      video_loaded: false,
      progress_id: undefined,
      progress: {},
      key: 0,
      feedback_active: false
    };
  },
  methods: {
    previousLesson() {
      let ids = this.module.lessons.map(lesson => lesson.id);

      for (let i = 0; i < ids.length; i++) {
        if (ids[i] === this.lesson.id) {

          if (i > 0) {
            this.goToLesson(ids[i - 1], this.module.id);
          } else {

            this.$vs.notify({
              color: 'danger',
              title: 'Failed to Proceed',
              text: 'You have reached the beginning of this module.',
            });
          }

        }
      }

      return false;
    },
    nextLesson() {

      let ids = this.module.lessons.map(lesson => lesson.id);

      for (let i = 0; i < ids.length; i++) {
        if (ids[i] === this.lesson.id) {

          if (i < ids.length - 1) {
            this.goToLesson(ids[i + 1], this.module.id);
          } else {
            console.log(this.module)
            if(this.module.end_of_module_iframe_url) {
              this.feedback_active = true
            }else {
              this.moduleComplete()
            }
          }

        }
      }

      return false;
    },
    moduleComplete () {
      this.$router.push(`/courses/${this.course.id}?opt=continue`);
    },
    markAsComplete(force = false, redirect = true) {

      if (this.lesson.quiz_id && this.quiz && force === false) {
        return this.quiz_active = true
      }

      // this.$vs.loading();

      this.$http.post(`courses/progress/${this.progress_id}/mark`, {
        module_id: this.module.id,
        lesson_id: this.lesson.id,
      })
        .then(response => {
          // this.$vs.loading.close();

          this.$vs.notify({
            color: 'success',
            title: 'Lesson Complete',
            text: 'You have successfully completed this lesson',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            position: 'top-right'
          });

          if(response.data && redirect) {
            this.nextLesson();
          }

        })
        .catch(exception => {
          this.$vs.loading.close();
          if (exception.response) {

          }

        });

    },
    goToLesson(lesson_id, module_id) {
      this.quiz = undefined
      this.quiz_active = false
      this.$router.push(`/courses/${this.course.id}/${module_id}/${lesson_id}`);
      this.initLesson();
    },
    initiateDownload(attachId) {

      this.$vs.loading();

      this.$http.get(`attachments/${attachId}/download`)
        .then(response => {
          this.$vs.loading.close();

          if (response.data) {
            const payload = response.data.data;
            window.open(payload.url, '_blank');
          }

        })
        .catch(exception => {

          this.$vs.loading.close();

        });

    },
    loadProgress(courseId) {
      this.$http.get(`courses/progress/course/${courseId}`)
        .then(response => {

          if (response.data) {
            this.progress = response.data.data;
            this.progress_id = this.progress.id;
          }

        })
        .catch(exception => {

          if (exception.response) {

          }


        });
    },
    initLesson() {
      
      this.feedback_active = false
      this.quiz_skip = false
      this.quiz = undefined;
      this.quiz_active = false;
      this.video_loaded = false;
      this.progress = {};
      this.key = 0;
      this.quiz_key = 0;

      const {courseId, moduleId, lessonId} = this.$route.params;

      this.$http.get(`courses/${courseId}/rich`)
        .then(response => {

            if (response.data) {
              this.course = response.data.data;
              this.loadProgress(courseId);

              this.module = this.course.modules.filter(module => module.id === moduleId)[0];

              this.$http.get(`lessons/${lessonId}?attachments=true`)
                .then(response => {

                  if (response.data) {
                    this.lesson = response.data.data;

                    try {
                      Vue.prototype.$gateway.updatePresence('WATCHING', this.lesson.name);

                    } catch (e) {

                    }

                    setTimeout(() => {
                      if (this.lesson.attachment_ids.length > 0) {
                        this.$refs.attachments.toggleContent();
                      }
                    }, 250);

                    this.$http.get(`videos/${this.lesson.video_id}`)
                      .then(response => {
                        if (response.data) {
                          this.video = response.data.data;

                          this.$http.post(`videos/${response.data.data.id}/watch?course_id=${this.course.id}`)
                            .then(response => {
                              if (response.data) {
                                this.osidex_id = response.data.data;
                                this.key++
                              }
                            });

                        }
                      });

                    if (this.lesson.quiz_id) {
                      this.$http.get(`lessons/${this.lesson.id}/quiz`)
                        .then(response => {
                          if (response.data) {
                            this.quiz = response.data.data;

                            this.quiz.questions.forEach((item, index) => {
                              this.quiz.questions[index].selected = null
                            })

                          }
                        });
                    }

                  }
                });
            }
          },
        );
    },
    /* quizMark() {

       let answers = {}

       this.quiz.questions.forEach((item, index) => {

         const selected = item.selected
         answers[index] = `${selected}`

         if (selected === false) {
           this.questionChooseAnswer(index, 'missing')
         }

       })

       this.$http.post(`lessons/${this.lesson.id}/quiz/submissions`, {"answers": answers})
         .then(response => {
           if (response.data) {

             const updatedAnswer = Object.assign({}, this.quiz,
               {
                 'total_questions': response.data.data.total_questions,
                 'total_correct': response.data.data.total_correct
               });

             this.$set(this.quiz, 'response', updatedAnswer)

           }
         });

     }, */
    quizStart () {
      if(this.quiz_skip) this.markAsComplete()
    },
    quizRestart () {
      this.quiz = undefined;
      this.$http.get(`lessons/${this.lesson.id}/quiz`)
        .then(response => {
          if (response.data) {
            this.quiz = response.data.data;

            this.quiz.questions.forEach((item, index) => {
              this.quiz.questions[index].selected = null
            })
          }
        });
      this.quiz_key++
    },
    quizComplete () {
      this.progress.modules[this.module.id][this.lesson.id] = true
      this.markAsComplete(true, false);
    },
    quizEnd() {
      this.quiz = undefined
      this.quiz_active = false
      this.nextLesson();

    },
    videoEnded() {
      this.markAsComplete();
    },

  },
  mounted() {
    this.quiz = undefined
    this.quiz_active = false
    this.initLesson();

    this.wasSidebarOpen = this.$store.state.reduceButton
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  },
  beforeDestroy() {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
  }
};
</script>
<style lang="scss">
@import "@/assets/css/new-loader.scss";

#ModuleSelector {
  .vs-collapse-item--header {
    padding: 0 !important;
  }

  .con-content--item {
    padding: 0 !important;
  }
}

#AppCollapse {
  .vs-collapse-item--header {
    padding: 0 !important;
  }
}


.iframe-video-player {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0
}

.iframe-video-holder {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

</style>
